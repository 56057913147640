
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "6c201610-9767-4577-bd90-0dd142198ad6"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/rialtic/workflows/console-ui/apps/console/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
